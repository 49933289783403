import React from "react";
import { Formik } from "formik";
import { Link } from "gatsby";
import {
  carer,
  professional,
  survivor,
  unknown,
  validationSchema,
  validationSchemaWithMessage,
  validStakeholderToLabel,
  friend,
  expert,
  searchEngine,
  socialMedia,
  ourNetwork,
  other,
  validChannelToLabel,
} from "./validationSchema";

export const defaultInitialValues = {
  firstName: "",
  lastName: "",
  email: "",
  stakeholder: "",
  country: "",
  occupation: "",
  mailingList: false,
  testingAndFeedback: false,
  message: "",
  channel: "",
  agreeToStrategicPartners: false,
};
const SignUpForm = ({
  withMessage = false,
  initialValues = defaultInitialValues,
  onSubmit = (
    values = defaultInitialValues,
    { setSubmitting } = { setSubmitting: (value = true) => null }
  ) => null,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={
        withMessage ? validationSchemaWithMessage : validationSchema
      }
      onSubmit={onSubmit}
      render={({
        values,
        errors,
        touched,
        isSubmitting,
        handleSubmit,
        handleChange,
        handleBlur,
      }) => {
        return (
          <form
            name="sign-up"
            onSubmit={handleSubmit}
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            {/* firstname - lastname */}
            <div className="columns">
              <div className="column is-half">
                <div className="field">
                  <label
                    className={`label ${
                      touched.firstName && errors.firstName && "has-text-danger"
                    }`}
                  >
                    First Name*
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        name="firstName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </label>
                </div>
              </div>
              <div className="column is-half">
                <div className="field">
                  <label
                    className={`label ${
                      touched.lastName && errors.lastName && "has-text-danger"
                    }`}
                  >
                    Last Name*
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        name="lastName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </label>
                </div>
              </div>
            </div>

            {/* email */}
            <div className="field">
              <label
                className={`label ${
                  touched.email && errors.email && "has-text-danger"
                }`}
              >
                Email*
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </label>
            </div>

            {/* country */}
            <div className="field">
              <label
                className={`label ${
                  touched.country && errors.country && "has-text-danger"
                }`}
              >
                Country
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    name="country"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </label>
            </div>

            {/* which describes you best? survivor,family-member,generic-clinician,unknown */}
            <div className="field">
              <label
                className={`label ${
                  touched.stakeholder && errors.stakeholder && "has-text-danger"
                }`}
              >
                Which describes you best?*
                <div className="control">
                  {/* // eslint-disable-next-line jsx-a11y/label-has-associated-control 
                  – this is triggered due to the div but it is required for styling */}
                  <div className="select">
                    <select
                      value={values.stakeholder}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="stakeholder"
                    >
                      <option value="">Select an option</option>
                      <option value={survivor}>
                        {validStakeholderToLabel[survivor]}
                      </option>
                      <option value={carer}>
                        {validStakeholderToLabel[carer]}
                      </option>
                      <option value={professional}>
                        {validStakeholderToLabel[professional]}
                      </option>
                      <option value={unknown}>
                        {validStakeholderToLabel[unknown]}
                      </option>
                    </select>
                  </div>
                </div>
              </label>
            </div>

            {/* social media channel */}
            <div className="field">
              <label
                className={`label ${
                  touched.channel && errors.channel && "has-text-danger"
                }`}
              >
                How did you find us?*
                <div className="control">
                  {/* // eslint-disable-next-line jsx-a11y/label-has-associated-control 
                  – this is triggered due to the div but it is required for styling */}
                  <div className="select">
                    <select
                      value={values.channel}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="channel"
                    >
                      <option value="">Select an option</option>
                      <option value={friend}>
                        {validChannelToLabel[friend]}
                      </option>
                      <option value={expert}>
                        {validChannelToLabel[expert]}
                      </option>
                      <option value={searchEngine}>
                        {validChannelToLabel[searchEngine]}
                      </option>
                      <option value={socialMedia}>
                        {validChannelToLabel[socialMedia]}
                      </option>
                      <option value={ourNetwork}>
                        {validChannelToLabel[ourNetwork]}
                      </option>
                      <option value={other}>
                        {validChannelToLabel[other]}
                      </option>
                    </select>
                  </div>
                </div>
              </label>
            </div>

            {/* if professional/unknown – occupation */}
            {(values.stakeholder === professional ||
              values.stakeholder === unknown) && (
              <div className="field">
                <label
                  className={`label ${
                    touched.occupation && errors.occupation && "has-text-danger"
                  }`}
                >
                  Occupation*
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      name="occupation"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </label>
              </div>
            )}

            {/* if get-in-touch - message */}
            {withMessage && (
              <div className="field">
                <label
                  className={`label ${
                    touched.message && errors.message && "has-text-danger"
                  }`}
                >
                  Message*
                  <div className="control">
                    <textarea
                      className="textarea"
                      type="textarea"
                      name="message"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Message"
                    />
                  </div>
                </label>
              </div>
            )}

            {/* I would like to join neumind's mailing list */}
            {withMessage && (
              <div className="field">
                <div className="control">
                  <label className="checkbox has-text-weight-normal">
                    <input
                      className="mr-3"
                      type="checkbox"
                      name="mailingList"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    I would like to join neumind's mailing list.
                  </label>
                </div>
              </div>
            )}

            {/* I would like to help with testing and get early access to neumind / I would be interested in helping neumind develop tools, or in becoming a neumind specialist */}
            <div className="field">
              <div className="control">
                <label className="checkbox">
                  <input
                    className="mr-3"
                    type="checkbox"
                    name="testingAndFeedback"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {values.stakeholder === professional
                    ? "I would be interested in helping neumind develop their products, and/or becoming a neumind specialist."
                    : "I would like to help with testing and get early access to neumind products."}
                </label>
              </div>
            </div>

            {/* I agree to my data being shared with strategic partners to improve Neumind products and services. */}
            <div className="field">
              <div className="control">
                <label
                  className={`checkbox ${
                    touched.agreeToStrategicPartners &&
                    errors.agreeToStrategicPartners &&
                    "has-text-danger"
                  }`}
                >
                  <input
                    className="mr-3"
                    type="checkbox"
                    name="agreeToStrategicPartners"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  I agree to my data being shared with strategic partners to
                  improve neumind products and services.*
                </label>
              </div>
            </div>

            <div className="control pt-3 has-text-left">
              <button
                className="button is-primary is-large is-rounded"
                type="submit"
                disabled={isSubmitting}
              >
                Submit
              </button>
              {withMessage ? (
                <label className="label has-text-weight-normal pt-3">
                  By sending your message, you agree that your data is processed
                  according to our{" "}
                  <Link to="/privacy-policy">privacy policy</Link>, where
                  further information can be found.
                </label>
              ) : (
                <label className="label has-text-weight-normal pt-3">
                  Further information about how Neumind will process your
                  personal data can be found in our{" "}
                  <Link to="/privacy-policy">privacy policy</Link>. You can
                  unsubscribe at any time.
                </label>
              )}
            </div>
          </form>
        );
      }}
    />
  );
};

export { SignUpForm };
