import React, { useState } from "react";
import { Link } from "gatsby";

import { LayoutBox } from "../../components/atoms/LayoutBox";
import {
  defaultInitialValues,
  SignUpForm,
} from "../../components/forms/SignUp";
import {
  carer,
  professional,
  survivor,
} from "../../components/forms/SignUp/validationSchema";
import Layout from "../../components/Layout";
import { TextBanner } from "../../components/molecules/TextBanner";
import { useURLParam } from "../../hooks/useURLParam";

const SignUp = () => {
  const defaultTitle = "Sign up";

  const [finalValues, setFinalValues] = useState({});

  const { paramValue: withMessageParam, isLoading: withMessageLoading } =
    useURLParam("with-message");
  const withMessage = withMessageParam === "true";

  const { paramValue: isProfessionalParam, isLoading: isProfessionalLoading } =
    useURLParam("is-professional");
  const isProfessional = isProfessionalParam === "true";

  const title = withMessage ? "Get in touch" : defaultTitle;

  const description = withMessage
    ? "Send us a message. We will get back to you soon."
    : "Sign up to our mailing list here.";

  return (
    <Layout title={title}>
      {withMessageLoading || isProfessionalLoading ? (
        <progress className="progress is-info" max="100" />
      ) : (
        <div>
          <TextBanner title={title} />
          <LayoutBox>
            {Object.keys(finalValues).length === 0 ? (
              <>
                <p>{description}</p>
                <br />

                <SignUpForm
                  withMessage={withMessage}
                  initialValues={{
                    ...defaultInitialValues,
                    stakeholder: isProfessional ? professional : "",
                    mailingList: !withMessage,
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    fetch("/.netlify/functions/hs-sign-up", {
                      // eslint-disable-line
                      method: "POST",
                      body: JSON.stringify({
                        profile: {
                          firstName: values.firstName,
                          lastName: values.lastName,
                          email: values.email,
                          stakeholder: values.stakeholder,
                          country: values.country,
                          occupation: values.occupation,
                          mailingList: values.mailingList,
                          testingAndFeedback: values.testingAndFeedback,
                          message: values.message,
                          channel: values.channel,
                          agreeToStrategicPartners:
                            values.agreeToStrategicPartners,
                        },
                        withMessage,
                      }),
                    })
                      .then((response) => {
                        if (
                          response.status === 200 ||
                          response.status === 201
                        ) {
                          setSubmitting(false);
                          setFinalValues(values);
                        } else {
                          alert(
                            `Error ${response.status}: ${response.statusText}.`
                          ); //eslint-disable-line
                          setSubmitting(false);
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                        alert("Error: Please Try Again!"); //eslint-disable-line
                        setSubmitting(false);
                      });
                  }}
                />
              </>
            ) : (
              <div className="content">
                <p>
                  {withMessage
                    ? "Thank you, your message has successfully been sent."
                    : "Thank you for signing up for neumind."}
                </p>
                {(finalValues.stakeholder === survivor ||
                  finalValues.stakeholder === carer) && (
                  <>
                    <p>Please sign up below to join.</p>
                    <div className="columns is-centered pt-4">
                      <div className="column is-3 pt-0">
                        <Link
                          to="/onboarding"
                          className="button is-rounded is-medium is-primary"
                        >
                          Sign up
                        </Link>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </LayoutBox>
        </div>
      )}
    </Layout>
  );
};

export default SignUp;
