import * as yup from "yup";

export const survivor = "Survivor";
export const carer = "Family Member";
export const professional = "Generic Clinician";
export const unknown = "Unknown";
export const validStakeholders = [survivor, carer, professional, unknown];

export const validStakeholderToLabel = {
  [survivor]: "I have a neurological injury or condition",
  [carer]: "I am a family member or caregiver",
  [professional]: "I am a professional in this field",
  [unknown]: "Other",
};

export const friend = "Recommendation from a friend or family member";
export const expert = "Recommendation from a healthcare professional";
export const searchEngine = "Search Engine (e.g. Google)";
export const socialMedia = "Social Media (e.g. Google)";
export const ourNetwork = "Our Network";
export const other = "other";
export const validChannels = [
  friend,
  expert,
  searchEngine,
  socialMedia,
  ourNetwork,
  other,
];

export const validChannelToLabel = {
  [friend]: friend,
  [expert]: expert,
  [searchEngine]: searchEngine,
  [socialMedia]: "Social Media (e.g. Facebook, Twitter)",
  [ourNetwork]: ourNetwork,
  [other]: "Other",
};

export const validationSchema = yup.object({
  firstName: yup.string().required().label("First name"),
  lastName: yup.string().required().label("Last name"),
  email: yup.string().email().required(),
  stakeholder: yup.string().oneOf(validStakeholders).required(),
  country: yup.string().optional(),
  occupation: yup.string().when("stakeholder", {
    is: professional,
    then: yup.string().required(),
  }),
  mailingList: yup.boolean().default(false),
  testingAndFeedback: yup.boolean().default(false),
  agreeToStrategicPartners: yup.boolean().default(false).oneOf([true]),
  channel: yup.string().oneOf(validChannels).required(),
});

export const validationSchemaWithMessage = validationSchema.concat(
  yup.object({
    message: yup.string().required().max(5000),
  })
);
